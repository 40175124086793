import React from 'react'

export default function Mission() {
  return (
    <div>Mission</div>
    // Making the backend a frontend
    // Backend Engineers that have frontend awereness
    // Bicyles for the Mind


    // Billing: 
    // Individual, Enterprise, Custom 
    // 2 development of unit-tested features per month (frontend + backend) with a clear established SLA
    // (can be new API endpoint, new data connectors, new frontend feature, adding features to the Flow Designer..)
    // Monetize your content
    // 50, 500
  )
}
