// Home.js
import React from 'react';
import './Home.css';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SEO from '../Components/SEO';
import WordSequence from '../Components/WordSequence';

function Home() {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.8,
        when: 'beforeChildren',
        staggerChildren: 0.2,
      },
    },
  };

  const contentVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut',
      },
    },
  };

  return (
    <>
      <SEO
        title="Info-com.io: Orchestrating Intelligence"
        description="Info-com.io is a platform for exploring and integrating intelligence across various domains. It provides tools for understanding complex systems, analyzing data, and generating insights. Products like financial-portfolio.io offer financial research tools, including metrics, news analysis, and trend insights, while other solutions focus on general-purpose intelligence and data flow engineering."
        keywords="intelligence platform, AI-driven insights, financial research, historical data, investment analysis, media exploration, general-purpose intelligence, data systems, flow engineering, chain of thought"
        canonical="https://info-com.io/"
      />

      <motion.main
        className="main_div_home"
        initial="initial"
        animate="animate"
        variants={pageVariants}
      >
      <video
        autoPlay
        loop
        muted
        playsInline
        className="background-video"
      >
        <source src="/intelligence.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>


        <Container className="content-container">
          <motion.div variants={contentVariants} className="main_container">
            <div className="left_section">
              <motion.div variants={contentVariants}>
                <motion.h1 className="headline" variants={contentVariants}>
                  Orchestrating Intelligence
                </motion.h1>
                <motion.div variants={contentVariants}>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <Link to="/products" className="custom-btn">
                      Explore Our Technology
                    </Link>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
            <div className="right_section">
              {/* Right section content if needed */}
            </div>
          </motion.div>
          <WordSequence />
        </Container>
      </motion.main>
    </>
  );
}

export default Home;
