// src/Screens/Products.js
import React, { useState } from 'react';
import styles from './Products.module.css';
import financialPortfolioLogo from '../assets/poly.svg';
import fp_preview_1 from '../Screens/assets_screens/fp_1.png';
import fp_preview_2 from '../Screens/assets_screens/fp_2.png';
import fp_preview_3 from '../Screens/assets_screens/fp_3.png';
import fp_preview_4 from '../Screens/assets_screens/fp_4.png';

export default function Products() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const products = [
        {
            name: "financial-portfolio.io",
            logo: financialPortfolioLogo,
            description: [
                "Financial-portfolio.io is a financial research platform designed for investors and researchers, featuring 30 years of data on more than 60,000 listed companies worldwide.",
                "We developed a Reasoning Flow Designer that enables users to methodically replicate the same research and analysis process across multiple companies.",
                "In addition to traditional financial metrics, the platform includes a Financial News Media Explorer that aggregates news from diverse sources and formats, including mainstream outlets and alternative sources.",
                "The information explorer can be connected to your reasoning flow and provides an API endpoint for integration into your systems.",
            ],
            url: "https://www.financial-portfolio.io/",
            previews: [
                fp_preview_1, 
                fp_preview_2, 
                fp_preview_3, 
                fp_preview_4,
            ],
        },
    ];
    
    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % products[0].previews.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + products[0].previews.length) % products[0].previews.length);
    };

    return (
        <div className="dark-bg-page">
            <div className={styles.mainContainer}>
                <div className={styles.productSection}>
                <div className={styles.productInfo}>
                    <img 
                        src={products[0].logo} 
                        alt={`${products[0].name} logo`} 
                        style={{ display: 'block', margin: 'auto', padding:'0.5rem', width: '100px', height: 'auto' }} 
                    />
                    <h1 style={{ textAlign: 'center', marginTop: '1rem' }}>{products[0].name}</h1>
                    {products[0].description.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                    ))}
                    <a 
                        href={products[0].url} 
                        className={styles.exploreButton} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Explore Our Product
                    </a>
                </div>
                    
                    <div className={styles.previewSection}>
                        <div className={styles.carouselContainer}>
                            <button onClick={prevSlide} className={styles.carouselButton}>&lt;</button>
                            <img 
                                src={products[0].previews[currentSlide]} 
                                alt={`${products[0].name} preview`} 
                                className={styles.previewImage} 
                            />
                            <button onClick={nextSlide} className={styles.carouselButton}>&gt;</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}