// WordSequence.js
import React, { useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './WordSequence.module.css';

const WordSequence = () => {
  // Array of words to display in sequence
  const words = [
    "Memory",
    "Adaptability",
    "Systems Thinking",
    "Context",
    "Method",
    "Attention",
    "Information",
    "Logical Reasoning",
    "Reflexivity",
    "Reasoning",
    "Chains of Thought",
    "Synthesis",
    "Relations",
    "Associations",
    "Flow Engineering",
    "Symbolic Reasoning",
  ];
  
  // State to track current word index
  const [currentIndex, setCurrentIndex] = React.useState(0);

  // State to track the current x,y position of the displayed word
  const [position, setPosition] = React.useState({ x: 0, y: 0 });

  // Track dimensions of the viewport (or container)
  const [containerDimensions, setContainerDimensions] = React.useState({
    width: 0,
    height: 0
  });

  // Ref to measure the word element's dimensions (width, height)
  const wordRef = React.useRef(null);

  // Update container dimensions on initial load and on window resize
  React.useEffect(() => {
    const updateDimensions = () => {
      setContainerDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // Function to randomly generate a new position for the next word
  const generatePosition = useCallback(() => {
    if (!wordRef.current) return { x: 0, y: 0 };

    const wordWidth = wordRef.current.offsetWidth;
    const wordHeight = wordRef.current.offsetHeight;
    
    // Detect if we are on a mobile screen
    const isMobile = window.innerWidth <= 768;

    // Increase padding a bit to ensure words aren't clipped at the edges
    const padding = isMobile ? 30 : 40;

    // Define safe areas for word placement:
    // We break down the screen into top, bottom, and side areas.
    // On mobile, we exclude the middle rectangle sections to prevent overlap with center content.
    const safeAreas = isMobile
      ? [
          // TOP SECTION (MOBILE)
          {
            minX: padding,
            maxX: containerDimensions.width - padding - wordWidth,
            minY: padding,
            maxY: containerDimensions.height * 0.3 - wordHeight
          },
          // BOTTOM SECTION (MOBILE)
          {
            minX: padding,
            maxX: containerDimensions.width - padding - wordWidth,
            minY: containerDimensions.height * 0.7,
            maxY: containerDimensions.height - padding - wordHeight
          }
        ]
      : [
          // TOP SECTION (DESKTOP)
          {
            minX: padding,
            maxX: containerDimensions.width - padding - wordWidth,
            minY: padding,
            maxY: containerDimensions.height * 0.3 - wordHeight
          },
          // BOTTOM SECTION (DESKTOP)
          {
            minX: padding,
            maxX: containerDimensions.width - padding - wordWidth,
            minY: containerDimensions.height * 0.7,
            maxY: containerDimensions.height - padding - wordHeight
          },
          // LEFT SIDE MIDDLE SECTION (DESKTOP)
          {
            minX: padding,
            maxX: (containerDimensions.width * 0.3) - wordWidth,
            minY: containerDimensions.height * 0.3,
            maxY: containerDimensions.height * 0.7 - wordHeight
          },
          // RIGHT SIDE MIDDLE SECTION (DESKTOP)
          {
            minX: containerDimensions.width * 0.7,
            maxX: containerDimensions.width - padding - wordWidth,
            minY: containerDimensions.height * 0.3,
            maxY: containerDimensions.height * 0.7 - wordHeight
          }
        ];

    // Randomly choose one safe area to place the word
    const selectedArea = safeAreas[Math.floor(Math.random() * safeAreas.length)];

    const x = Math.random() * (selectedArea.maxX - selectedArea.minX) + selectedArea.minX;
    const y = Math.random() * (selectedArea.maxY - selectedArea.minY) + selectedArea.minY;

    return { x, y };
  }, [containerDimensions]);

  // Update the displayed word at intervals and reposition it
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % words.length);
      setPosition(generatePosition());
    }, 2500);
    
    return () => clearInterval(timer);
  }, [words.length, generatePosition]);

  return (
    <div className={styles.wordSequence}>
      <AnimatePresence mode="wait">
        <motion.div
          ref={wordRef}
          key={words[currentIndex]}
          initial={{ opacity: 0, x: position.x, y: position.y }}
          animate={{ 
            opacity: 1,
            x: position.x,
            y: position.y
          }}
          exit={{ opacity: 0 }}
          transition={{ 
            duration: 1.2,
            ease: "easeInOut"
          }}
          className={styles.sequenceWord}
        >
          {words[currentIndex]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default WordSequence;
